.popup {
	width: 160px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 8px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -80px;
}

/* Popup arrow */
.popup::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}
body {
	margin: 0;
}
#root {
	height: 100vh;
	width: 100vw;
}

.ol-zoom {
	margin-top: 40px;
}

.ol-control {
	margin-top: 20px;
}

.ol-rotate {
	margin-top: 80px;
}

.layer-switcher {
	background-color: rgba(0, 60, 136, 0.5);
	border: 3px solid rgba(255, 255, 255, 0.6);
	margin-top: -27px;
}
.layer-switcher button {
	filter: grayscale(100%) brightness(110%);
	background-color: rgba(0, 0, 0, 0);
}
